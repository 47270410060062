<!--
 * @Descripttion: 左侧导航头部卡片
 * @version: 
 * @Author: year
 * @Date: 2020-11-25 10:00:53
 * @LastEditors: Chen
 * @LastEditTime: 2021-01-21 17:14:08
-->
<template>
  <div class="top-title-container">
    <div class="wrap">
      <div class="top-img">
        <img width="194px" height="30px" :src="logoImg" alt />
      </div>
      <div class="text-desc">
        <div class="china-text">华云慧能平台Version1.0</div>
        <div class="en-text">Intelligent Energy Sources Platform of Huayun</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      logoImg: require("@/assets/images/brand_logo.png")
    };
  },
  methods: {},
  components: {}
};
</script>

<style lang='scss' scoped>
.top-title-container {
  height: 320px;
  background: #1a2137;
  border-radius: 0 25px 0 0;
  .wrap {
    height: 100%;
    border-radius: 0 25px 25px 0;
    box-shadow: 0 0 10px #141414;
    background: $brand-bg;
  }
  .top-img {
    padding: 50px 30px;
  }
  .text-desc {
    padding: 40px;
    padding-left: 15px;
    .china-text {
      width: 200px;
      font-size: 16px;
      padding-bottom: 15px;
      padding-left: 10px;
      border-bottom: 2px solid #ffff;
    }
    .en-text {
      margin-top: 10px;
      font-size: 14px;
      width: 180px;
      font-weight: 400;
      line-height: 20px;
    }
  }
}
</style>
