<!--
 * @Author: wuruoyu
 * @Description: 导航
 * @Date: 2020-12-22 14:05:58
 * @LastEditors: year
 * @LastEditTime: 2021-01-15 16:54:09
 * @FilePath: \huayun_platform\src\layout\Siderbar.vue
-->
<template>
  <div class="sidebar">
    <TopTitleCard></TopTitleCard>
    <div class="menubox">
      <SubMenuList :list="subList"></SubMenuList>
    </div>
  </div>
</template>

<script>
import TopTitleCard from "./TopTitleCard";
import SubMenuList from "./component/SubMenuList";
import { menuRoute } from "@/router/index";
import { getCookie } from "@/utils/public.js"
export default {
  data() {
    return {
      isCollapse: true,
      subList: []
    };
  },
  computed: {
    routerList() {
      return menuRoute.children;
    },
  },
  components: {
    TopTitleCard,
    SubMenuList,
  },
  methods: {
    menuOpen() {
      this.isCollapse = false;
    },
    menuClose() {
      this.isCollapse = true;
    },
    menuClick(data) {
      this.subList = data.children || [];
      this.isCollapse = true;
    },
    getParentUrl() {
      let url = null
      if (parent !== window) {
        try {
          url = parent.location.href
        }catch (e) {
          url = document.referrer
        } 
      }
      return url
    }
  },
  mounted() {
    const userInfoStr = getCookie("userInfo")
    if(userInfoStr) {
      const userInfo = JSON.parse(userInfoStr)
      const { account } = userInfo
      if('工具化测试' === account) {
        this.subList = require('@/assets/data/toolsMenuList.json')
      } else {
        this.subList = require('@/assets/data/platforMenuList.json')
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  width: 260px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: $side-bg;
  .menubox {
    flex: 1;
    padding-left: 50px;
    overflow: auto;
    position: relative;
    &::-webkit-scrollbar {
      width: 0 !important;
    }
  }
  .collapse-menu {
    width: 260px;
    border: none;
  }
  .first-menu {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    & /deep/ .el-menu-item {
      padding: 0 10px !important;
      height: 60px;
      font-size: 18px;
      font-weight: 400;
      i {
        font-size: 24px;
      }
    }
    & /deep/ .el-menu--collapse {
      width: 50px;
      border: none;
    }
    & /deep/ .el-tooltip {
      padding: 0 10px !important;
      width: 50px;
      display: flex;
      justify-content: center;
    }
  }
  .toggle-menu-item {
    height: 59px;
    width: 100%;
    border-bottom: 1px solid #53545a;
  }
}
</style>

<style lang="scss">
.sidebar {
  .el-menu-item {
    height: 58px !important;
  }
}
</style>
