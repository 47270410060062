<!--
 * @Author: wuruoyu
 * @Description: 
 * @Date: 2020-12-23 14:52:15
 * @LastEditors: year
 * @LastEditTime: 2021-01-25 09:59:53
 * @FilePath: \huayun_platform\src\layout\component\SubMenuList.vue
-->
<template>
  <div class="submenu-list">
    <el-menu
      :default-active="$route.name"
      class="submenu-self"
      :show-timeout="200"
      :hide-timeout="200"
      vertical="vertical"
      background-color="#1A2137"
      text-color="#8E919C"
      active-text-color="#ffffff"
      >
      <template v-for="subitem in list">
        <el-submenu 
          v-if="subitem.children && subitem.children.length" 
          :index="subitem.name"
          :key="subitem.name"
          >
          <template slot="title">
            <i :class="subitem.meta.icon"></i>
            <span>{{subitem.meta.title}}</span>
          </template>
          <el-menu-item
            v-for="childitem in subitem.children"
            @click="routerJump(childitem)"
            :index="childitem.name" 
            :key="childitem.name">
            <span style="marginLeft:5px">{{childitem.meta.title}}</span>
          </el-menu-item>
        </el-submenu>
        <el-menu-item
          v-else
          @click="routerJump(subitem)"
          :index="subitem.name" 
          :key="subitem.name">
          <i :class="subitem.meta.icon"></i>
          <span slot="title">{{subitem.meta.title}}</span>
        </el-menu-item>
      </template>
    </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  props:{
    list:Array
  },
  methods:{
    routerJump(menu){
      this.$router.push({path:menu.meta.hash})
    }
  },
  components: {

  }
}
</script>

<style lang="scss" scoped>
  .submenu-self{
    border: none;
    padding-top: 30px;
    & /deep/ .is-active {
      color: #ffffff;
      position: relative;
      background-color: #16181D !important;
    }
    & /deep/ .is-active::after {
      content: '';
      display: inline-block;
      position: absolute;
      width: 22px;
      height: 96px;
      top: -20px;
      background-image: url("~@/assets/images/daohang.png");
      background-size: cover;
      right: 0;
      z-index: 1;
    }
    & /deep/ .el-menu-item {
      font-size: 18px;
      font-weight: 400;
    }
    & /deep/ .el-submenu__title {
      font-size: 18px;
      font-weight: 400;
    }
  }
</style>
